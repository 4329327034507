<template>
  <div class="BaseDetails">
    <ContainerCard :locationMsg="locationMsg" :width="65">
      <div class="titleInfo">
        <div class="groupRight">
          <span class="title1">{{ infoData.name }}</span>
          <div class="title2">
            <div class="top">
              <span class="word">
                <span class="label">性别</span>
                <span class="value">{{
                  infoData.sex === 0 ? "男" : "女"
                }}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="wordBottom">
                <span class="label">出生年月</span>
                <span class="value">{{ infoData.birthday }}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="wordBottom">
                <span class="label">单位</span>
                <span class="value">{{ infoData.company }}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="wordBottom">
                <span class="label">职务/职称</span>
                <span class="value">{{ infoData.post }}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="wordBottom">
                <span class="label">通讯地址</span>
                <span class="value">{{ infoData.address }}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="wordBottom">
                <span class="label">从事领域</span>
                <span class="value">{{ infoData.major }}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="wordBottom">
                <span class="label">从事领域工作年限</span>
                <span class="value">{{ infoData.major_year }}</span>
              </span>
            </div>
            <div class="bottom">
              <span class="wordBottom">
                <span class="label">{{ isEnterprise ? '所在企业所属产业' : '熟悉产业' }}</span>
                <span class="value" v-if="infoData.familiar_industry">{{ familiar_industry(infoData.familiar_industry)
                }}</span>
              </span>
            </div>

            <!-- 企业 -->
            <div class="bottom" v-if="isEnterprise">
              <span class="wordBottom">
                <span class="label">所在单位类型</span>
                <span class="value" v-if="infoData.familiar_industry">{{ familiar_industry(infoData.company_type, typeUnit)
                }}</span>
              </span>
            </div>
            <div class="bottom" v-if="isEnterprise">
              <span class="wordBottom">
                <span class="label">业务特长</span>
                <span class="value" v-if="infoData.familiar_industry">{{ familiar_industry(infoData.speciality, businessSpecialty)
                }}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="groupLeft">
          <el-image class="img1" referrerpolicy="no-referrer" v-if="infoData.photo"
            :src="httpConfig.baseURL + infoData.photo" />
        </div>
        <div class="no-imgbox" v-if="!infoData.photo">
          <el-image class="no-img" :src="defaultImg" alt="" />
        </div>
      </div>
      <!-- <div v-if="infoData.techList" class="inline"></div>
      <div v-if="infoData.techList" class="info">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">技术领域</span>
        </div>
        <div
          class="info-item"
          v-for="(item, index) in infoData.techList"
          :key="index"
        >
          {{ item }}
        </div>
      </div> -->
      <div class="inline"></div>
      <!-- <div class="info">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">专家描述</span>
        </div>
        <div class="info-value" >{{infoData.memo}}</div>
      </div> -->
      <!-- <div class="inline"></div> -->
      <div class="info" style="padding-bottom: 0px">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">合作咨询</span>
        </div>
        <div class="info-consulting">
          <div class="tit">福建省产教融合综合服务平台</div>
          <div class="consulting-item">
            <!-- <span class="label">联系地址</span> -->
            <span class="label">联系人</span>
            <span class="value">{{ infoData.name }}</span>
          </div>
          <div class="consulting-item">
            <span class="label">联系电话</span>
            <span class="value">{{ infoData.mobile }}</span>
          </div>
          <div class="consulting-item">
            <span class="label">邮箱</span>
            <span class="value">{{ infoData.email }}</span>
          </div>
        </div>
      </div>
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Consult from "../../components/Consult.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import { getExpertById, getSchExpertById, getEntExpertById } from "../../api/SixLibraries/Professor/Professor.js";
import { objDeepCopy } from "../../common/utils/tool";
import Contact from "../../components/SixLibraries/Contact.vue";
import { mapGetters } from "vuex";
import { getPlainText } from "../../common/utils/tool";
import httpConfig from "../../common/utils/config";
import defaultImg from "../../assets/images/用户默认.png";
import { occupationType, typeUnit, businessSpecialty } from '@/enmu/selectType.js'
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      defaultImg,
      httpConfig,
      typeUnit,
      businessSpecialty,
      infoData: {
        img: "https://tse1-mm.cn.bing.net/th/id/OIP-C.NHx7NfYNqUdH4kQG5argWwAAAA?w=182&h=131&c=7&r=0&o=5&dpr=1.25&pid=1.7",
        name: "高技能人才培训基地",
        body: "黎明职业大学、福建创想蓝图科技发展有限公司",
        level: "国家级",
        type: "跨境电商人才",
        num: "10",
        location: "中国北京市朝阳区朝阳门北大街22号",
        linkman: "莫老师",
        tel: "189****9999",
        description:
          "在合作办学上，周小华透露，目前新智公司正在与业界大咖中国保信集团对接。保信集团成功运营了北京曹妃甸国际职教城和贵州清镇职教城，有着丰富的经验。如果一切顺利，新智公司还计划与成都城投集团、中国保信集团深度合作，再成立一家管理运营公司，借成都城投集团的资金实力和保信集团的运营经验，参与园区的运营管理，统筹教育、社区、产业资源，推动职教学科建设、产业转型升级、企业创新转化等无缝对接、无障互通。",
      },
      type: [
        {
          label: "全部",
          value: "all",
        },
        { label: "国家级", value: "1" },
        { label: "省级", value: "2" },
        { label: "市级", value: "3" },
        { label: "县级", value: "4" },
        { label: "校级", value: "5" },
        { label: "其他", value: "6" },
      ],
      locationMsg: [
        { name: "七大库" },
        { name: "专家库", router: "Professor" },
        { name: "专家详情", router: "Professor/ProfessorDetails" },
      ],
      typeEnmu: {
        'enterprise': getEntExpertById,
        'occupation': getSchExpertById,
      },
      typeVal: '',
    };
  },
  computed: {
    familiar_industry() {
      return (val, oldObj = occupationType) => {
        if (!val) return ''
        const strArr = []
        const items = Object.entries(oldObj)
        items.forEach(item => {
          const arr = val.split(',')
          arr.forEach(it => {
            if (it === item[1]) {
              strArr.push(item[0])
            }
          })
        })
        return strArr.join(', ')
      }
    },

    isEnterprise() {
      return this.typeVal === 'enterprise'
    }
  },
  components: { Contact, ContainerCard, Location, Consult },
  created() {
    let id = this.$route.query.id;
    this.typeVal = this.$route.query.type;
    this.getData(id);
  },
  methods: {
    async getData(id) {
      let that = this;
      // typeVal = occupation:职教专家库 (getSchExperts)   typeVal = enterprise:企业行业专家库(getEntExperts)
      let API = this.typeEnmu[this.typeVal]
      if (!API) return
      let res = await API({ id: id });
      let tmp = objDeepCopy(res.data);
      const regex = new RegExp("<img", "gi");
      // tmp.memo =
      //   res.data?.memo &&
      //   res.data?.memo.replace(regex, '<img style="max-width: 100%;"');
      // tmp.techList = [];

      // Object.keys(that.techfieldList).forEach(function (key) {
      //   if (
      //     tmp?.tech_field &&
      //     tmp?.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
      //   ) {
      //     tmp.techList.push(key);
      //   }
      // });
      // tmp.memo = getPlainText(getPlainText(tmp.memo));
      this.infoData = tmp;
    },
  },
};
</script>
<style scoped lang="scss">
.titleInfo {
  width: 756px;
  // height: 260px;
  padding: 24px 0px 24px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .groupLeft {
    z-index: 126;

    // width: 110px;
    // height: 110px;
    .img1 {
      z-index: 127;
      width: 96px;
      height: 122px;
      border-radius: 6px;
    }
  }

  .no-imgbox {
    width: 72px;
    height: 92px;
    background: #F1F1F1;
    border-radius: 6px;
    text-align: center;
    line-height: 90px;

    .no-img {
      width: 24px;
      height: 24px;
    }
  }

  .groupRight {
    width: 680px;
    .title1 {
      width: 112px;
      height: 28px;
      color: rgba(16, 16, 16, 1);
      font-size: 24px;
      font-family: 思源黑体 CN Bold Bold;
      white-space: nowrap;
      line-height: 36px;
      text-align: left;
      font-weight: 600;
      // margin-left: 3px;
      margin-bottom: 6px;
    }

    .title2 {
      @mixin title2Font {
        font-size: 12px;
        font-family: 思源黑体 CN Regular;
        line-height: 18px;
        color: #8896a7;
      }

      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .word {
          .label {
            @include title2Font;
            margin-right: 12px;
          }

          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
      }

      .bottom {
        .wordBottom {
          .label {
            @include title2Font;
            margin-right: 12px;
          }

          .value {
            @include title2Font;
            display: inline-block;
            width: 620px;
            // 移出隐藏...
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #404040;
            font-weight: 500;
          }
        }
      }
    }

    .word {
      //   width: 178px;
      height: 16px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      font-family: SourceHanSansCN-Regular;
      white-space: nowrap;
      line-height: 16px;
      text-align: left;
      margin-top: 16px;
    }

    .wordBottom {
      //   width: 338px;
      height: 16px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      font-family: SourceHanSansCN-Regular;
      white-space: nowrap;
      line-height: 16px;
      text-align: left;
      margin-top: 8px;
    }
  }
}

.inline {
  //   width: 100%;
  height: 1px;
  width: 756px;
  background-color: rgba(216, 216, 216, 1);
  // margin-left: 30px;
  // margin-top: 24px;
}

.info {
  width: 756px;
  box-sizing: border-box;
  padding: 24px 0px 24px 0px;

  .info-title {
    margin-bottom: 12px;

    img {
      width: 8px;
      height: 10px;
      margin-right: 12px;
    }

    .label {
      font-size: 16px;
      font-family: 思源黑体 CN Medium;
      color: #101010;
      line-height: 24px;
    }
  }

  .info-item {
    display: inline-block;
    border-radius: 34.5px;
    padding: 10px 16px;
    border: 1px solid #f1f1f1;
    color: #101010;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background-color: #fafafa;
    font-family: 思源黑体 CN Regular;
  }

  .info-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #404040;
    font-family: 思源黑体 CN Regular;
    text-align: justify;
  }

  .info-consulting {
    .tit {
      background-color: #2885ff;
      padding: 6px;
      box-sizing: border-box;
      display: inline-block;
      border-radius: 2px 8px 8px 8px;
      color: #ffffff;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 20px;
    }

    .consulting-item {
      margin-bottom: 20px;

      .label {
        font-size: 12px;
        line-height: 18px;
        color: #8896a7;
        margin-right: 12px;
      }

      .value {
        font-size: 12px;
        line-height: 18px;
        color: #404040;
      }
    }
  }
}

::v-deep .locationList {
  position: absolute;
  left: -27px;
}

@media screen and (max-width: 768px) {}</style>